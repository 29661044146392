import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { BrandLogo } from '@moonpig/launchpad-assets'
import { SecondaryButton, Link, Flex } from '@moonpig/launchpad-components'

export const StyledLogo = styled(BrandLogo)`
  display: block;
  height: 52px;
  width: 100%;
  color: white;
`

export const StyledLink = styled(Link)`
  ${s({
    mb: 10,
  })}
`

export const StyledSecondaryButton = styled(SecondaryButton)`
  width: 100%;
  ${s({
    mb: 6,
  })}
`

export const StyledContainer = styled(Flex)`
  justify-content: center;
  max-width: 375px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  ${s({
    mt: 13,
    p: 6,
  })}
`

export const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${s({
    bgcolor: 'colorBackgroundSite',
  })}
`
